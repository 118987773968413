"use strict";

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Tabs = function Tabs() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var tabHeader = document.querySelectorAll('.tab-header');
    var tabContent = document.querySelectorAll('.tab-content');
    tabHeader.forEach(function (el) {
      var headerId = el.dataset.idHeader;
      el.addEventListener('click', function () {
        tabContent.forEach(function (c) {
          var contentId = c.dataset.idContent;
          var content = c;
          if (headerId === contentId) {
            content.classList.add('is-active');
          } else {
            content.classList.remove('is-active');
          }
        });
        tabHeader.forEach(function (i) {
          if (i.classList.contains('is-active')) {
            i.classList.remove('is-active');
          }
        });
        el.classList.add('is-active');
      });
    });
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();