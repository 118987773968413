"use strict";

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Accordion = function Accordion() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var accordionRow = document.querySelectorAll('.accordion-row');
    var debounce = function debounce(fn, d) {
      var timer;
      return function () {
        var context = this;
        var args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          fn.apply(context, args);
        }, d);
      };
    };
    var openAccordian = function openAccordian() {
      accordionRow.forEach(function (el) {
        var accordionText = el.querySelector(".accordion-text");
        var accordionSvg = el.querySelector('.plus-icon');
        accordionText.classList.add("is-active");
        accordionSvg.classList.add("is-open");
      });
    };
    var searchStart = function searchStart(e) {
      if (e.keyCode === 114 || e.ctrlKey && e.keyCode === 70) {
        // detect ctrl+f
        openAccordian();
      }
    };
    window.addEventListener("keydown", debounce(searchStart, 1000));
    accordionRow.forEach(function (el) {
      var accordionTitle = el.querySelector('.accordion-heading');
      var accordionText = el.querySelector('.accordion-text');
      var accordionSvg = el.querySelector('.plus-icon');
      accordionTitle.addEventListener('click', function () {
        if (accordionText.classList.contains('is-active')) {
          accordionText.classList.remove('is-active');
          accordionSvg.classList.remove('is-open');
        } else {
          accordionText.classList.add('is-active');
          accordionSvg.classList.add('is-open');
        }
      });
    });

    // Cards Accordions
    var cardsAccordions = document.querySelectorAll('[data-cards-accordion]');
    cardsAccordions.forEach(function (card) {
      var accordionTitle = card.querySelector('.card-topic-link');
      if (accordionTitle) {
        accordionTitle.addEventListener('click', function () {
          // Close all other accordions
          cardsAccordions.forEach(function (cardAccordion) {
            if (cardAccordion !== card) cardAccordion.classList.remove('is-active');
          });
          card.classList.toggle('is-active');
        });
      }
    });
    setTimeout(function () {
      var twitterFeed = document.querySelector('#twitter-widget-0');
      if (twitterFeed) {
        var twittos = twitterFeed.querySelector('.SandboxRoot');
        console.log(twittos);
      }
    }, 5000);
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();