"use strict";

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Version = function Version() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var version = document.querySelector('html').getAttribute('code-version');
    console.log(" \xF0\u0178\u017D\u2030 Code Version:%c ".concat(version, " "), 'color: #ff0000');
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();