"use strict";

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Header = function Header() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var links = document.querySelectorAll('.bottom-nav-link');
    var linksWrapper = document.querySelector('.sub-nav');
    var allSubNav = document.querySelectorAll('.bottom-sub-navigation');
    var mobileMenuBtn = document.querySelector('.mobile-menu-btn');
    var mobileMenu = document.querySelector('.mobile-menu');
    var overlay = document.querySelector('.overlay-mobile-menu');
    var header = document.querySelectorAll('header');
    var userBtn = document.querySelector('#account-loged');
    var userActionWrapper = document.querySelector('.user-actions');
    var userActionOverlay = document.querySelector('.overlay-desktop-transparent');
    var userBtnMobile = document.querySelector('#account-loged-mobile');
    var userActionWrapperMobile = document.querySelector('.user-actions.is-mobile');
    var userActionOverlayMobile = document.querySelector('.overlay-desktop-transparent.is-mobile');
    var mobileMenuHeader = document.querySelector('.mobile-menu-header');
    var closeSubNav = document.querySelectorAll('.close-sub-nav');
    var mobileLinksParent = document.querySelectorAll('.mobile-menu .link-parent');
    if (userBtn) {
      userBtn.addEventListener('click', function (e) {
        e.preventDefault();
        console.log('click1234');
        userBtn.classList.add('is-open');
        userActionWrapper.classList.add('is-open');
        userActionOverlay.classList.add('is-active');
      });
      userActionOverlay.addEventListener('click', function (e) {
        e.preventDefault();
        userBtn.classList.remove('is-open');
        userActionWrapper.classList.remove('is-open');
        userActionOverlay.classList.remove('is-active');
      });
    }
    if (userBtnMobile) {
      userBtnMobile.addEventListener('click', function (e) {
        e.preventDefault();
        userBtnMobile.classList.add('is-open');
        userActionWrapperMobile.classList.add('is-open');
        userActionOverlayMobile.classList.add('is-active');
        mobileMenuHeader.classList.add('account-is-active');
      });
      userActionOverlayMobile.addEventListener('click', function (e) {
        e.preventDefault();
        userBtnMobile.classList.remove('is-open');
        userActionWrapperMobile.classList.remove('is-open');
        userActionOverlayMobile.classList.remove('is-active');
        mobileMenuHeader.classList.remove('account-is-active');
      });
    }
    links.forEach(function (link) {
      var linkId = link.dataset.subLinks;
      if (linkId) {
        var currentSubNav = document.querySelector('#' + linkId);
        link.addEventListener('click', function (i) {
          i.preventDefault();
          if (currentSubNav) {
            allSubNav.forEach(function (s) {
              if (s.classList.contains('is-active')) {
                s.classList.remove('is-active');
              }
            });
            links.forEach(function (l) {
              if (l.classList.contains('has-border-bottom')) {
                l.classList.remove('has-border-bottom');
              }
            });
            var headerHeight = 0;
            header.forEach(function (e) {
              headerHeight += e.offsetHeight;
              headerHeight -= 1;
            });
            linksWrapper.classList.add('is-active');
            linksWrapper.style.top = headerHeight + 'px';
            link.classList.add('has-border-bottom');
            currentSubNav.classList.add('is-active');
          }
        });
        linksWrapper.addEventListener('mouseleave', function () {
          linksWrapper.classList.remove('is-active');
          currentSubNav.classList.remove('is-active');
          link.classList.remove('has-border-bottom');
        });
        closeSubNav.forEach(function (close) {
          close.addEventListener('click', function (i) {
            i.preventDefault();
            linksWrapper.classList.remove('is-active');
            currentSubNav.classList.remove('is-active');
            link.classList.remove('has-border-bottom');
          });
        });
      } else {
        link.addEventListener('mouseover', function () {
          linksWrapper.classList.remove('is-active');
          links.forEach(function (l) {
            if (l.classList.contains('has-border-bottom')) {
              l.classList.remove('has-border-bottom');
            }
          });
        });
      }
    });
    mobileMenuBtn.addEventListener('click', function (e) {
      e.preventDefault();
      mobileMenu.classList.add('is-active');
      overlay.classList.add('is-active');
      document.body.classList.add('no-scroll');
    });
    document.querySelector('.close-menu').addEventListener('click', function (e) {
      e.preventDefault();
      mobileMenu.classList.remove('is-active');
      overlay.classList.remove('is-active');
      document.body.classList.remove('no-scroll');
    });
    overlay.addEventListener('click', function (e) {
      e.preventDefault();
      mobileMenu.classList.remove('is-active');
      document.body.classList.remove('no-scroll');
      overlay.classList.remove('is-active');
    });
    mobileLinksParent.forEach(function (link) {
      var linkIdMobile = link.dataset.subId;
      if (linkIdMobile) {
        var currentMobileNav = document.querySelector('.links-children#' + linkIdMobile);
        link.addEventListener('click', function (i) {
          i.preventDefault();
          if (currentMobileNav.classList.contains('is-active')) {
            currentMobileNav.classList.remove('is-active');
          } else {
            currentMobileNav.classList.add('is-active');
            mobileMenu.scrollTop += 150;
          }
          if (link.classList.contains('is-open')) {
            link.classList.remove('is-open');
          } else {
            link.classList.add('is-open');
          }
        });
      } else {}
    });
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();