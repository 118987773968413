"use strict";

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Filters = function Filters() {
  /**
     * jQuery elements
     * @private
     */
  var $filters = document.querySelectorAll('[data-filters]');
  var $sorts = document.querySelectorAll('[data-sort]');
  var $jurisdiction = document.querySelectorAll('[data-jurisdiction]');
  var $legislationCheckboxes = document.querySelectorAll('[data-legislation-card]');
  var $mobileFilters = document.querySelectorAll('[data-filters-trigger]');
  var $mobileSorts = document.querySelectorAll('[data-sorts-trigger]');

  /**
     * Has the class been initialized?
     * @private
     */
  var inited = false;

  /**
     * Initializes the class.
     * @public
     */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    if ($filters.length) {
      $filters.forEach(function ($filter) {
        var $filtersForm = $filter.closest('form');
        $filter.addEventListener('change', function (e) {
          if ($filtersForm) {
            $filtersForm.submit();
          }
        });
        var $filtersClear = $filtersForm.querySelector('[data-filters-clear]');
        console.log($filtersClear);
        if ($filtersClear) {
          $filtersClear.addEventListener('click', function (e) {
            e.preventDefault();
            console.log('clear-filters');
            var $checkboxes = $filtersClear.parentNode.querySelectorAll('.filters [data-filters]');
            if ($checkboxes.length) {
              $checkboxes.forEach(function ($checkbox) {
                $checkbox.checked = false;
              });
            }
            if ($filtersForm) {
              $filtersForm.submit();
            }
          });
        }
      });
    }
    if ($mobileFilters.length) {
      $mobileFilters.forEach(function ($trigger) {
        var $parent = $trigger.closest('.block-wrapper');
        $trigger.addEventListener('click', function (e) {
          if ($parent) {
            var $filtersSidepanel = $parent.querySelector(".".concat($trigger.dataset.filtersTrigger));
            if ($filtersSidepanel) {
              $filtersSidepanel.classList.add('is-active');
              var $closeSidePanel = $filtersSidepanel.querySelector('.sidepanel-close');
              if ($closeSidePanel) {
                $closeSidePanel.addEventListener('click', function (e) {
                  e.preventDefault();
                  $filtersSidepanel.classList.remove('is-active');
                });
              }
            }
          }
        });
      });
    }
    if ($mobileSorts.length) {
      $mobileSorts.forEach(function ($trigger) {
        var $parent = $trigger.closest('.block-wrapper');
        $trigger.addEventListener('click', function (e) {
          if ($parent) {
            var $sortsSidepanel = $parent.querySelector(".".concat($trigger.dataset.sortsTrigger));
            if ($sortsSidepanel) {
              $sortsSidepanel.classList.add('is-active');
              var $closeSidePanel = $sortsSidepanel.querySelector('.sidepanel-close');
              if ($closeSidePanel) {
                $closeSidePanel.addEventListener('click', function (e) {
                  e.preventDefault();
                  $sortsSidepanel.classList.remove('is-active');
                });
              }
            }
          }
        });
      });
    }
    if ($sorts.length) {
      $sorts.forEach(function ($sort) {
        var $sortForm = $sort.closest('form');
        $sort.addEventListener('change', function (e) {
          if ($sortForm) {
            $sortForm.submit();
          }
        });
      });
    }
    if ($jurisdiction.length) {
      $jurisdiction.forEach(function ($category) {
        // const $filtersForm = $category.closest('form');
        var $filtersForm = document.querySelector('.filters-form');
        $category.addEventListener('click', function (e) {
          e.preventDefault();
          console.log('$category');
          var $newValue = $category.dataset.jurisdiction;
          if ($filtersForm) {
            var $hiddenCategoryInput = $filtersForm.querySelector('input[name="jurisdiction"]');
            if ($hiddenCategoryInput) {
              $hiddenCategoryInput.value = $newValue;
              $filtersForm.submit();
            }
          }
        });
      });
    }
    if ($legislationCheckboxes.length) {
      $legislationCheckboxes.forEach(function ($checkbox) {
        $checkbox.addEventListener('change', function () {
          if ($checkbox.checked) {
            $legislationCheckboxes.forEach(function (element) {
              if ($checkbox !== element) {
                element.checked = false;
              }
            });
          }
        });
      });
    }
    var url = window.location.href;
    if (url.includes('?')) {
      var $legislationListing = document.querySelector('#legislationsListing');
      if ($legislationListing) {
        var offsetTop = $legislationListing.offsetTop;
        scroll({
          top: offsetTop,
          behavior: 'smooth'
        });
      }
    }
    inited = true;
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();