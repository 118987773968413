"use strict";

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Sliders = function Sliders() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var slider = [].slice.call(document.querySelectorAll('.module-slider'));
    var sliderColors = [].slice.call(document.querySelectorAll('.module-color-slider'));
    var sliderAlert = [].slice.call(document.querySelectorAll('.alert-wrapper'));
    if (slider.length) {
      setTimeout(function () {
        slider.forEach(function ($slider) {
          var $sliderSection = $slider.querySelector('.slider-wrapper');
          var flkty = new Flickity($sliderSection, {
            // options
            resize: true,
            cellAlign: 'center',
            contain: true,
            imagesLoaded: true,
            adaptiveHeight: true,
            groupCells: false,
            lazyLoad: true,
            wrapAround: true,
            pageDots: true,
            prevNextButtons: true,
            autoPlay: true
          });
        });
      }, 200);
    }
    if (sliderColors.length) {
      setTimeout(function () {
        sliderColors.forEach(function ($slider) {
          var $sliderSection = $slider.querySelector('.slider-colors-wrapper');
          var flkty = new Flickity($sliderSection, {
            // options
            resize: true,
            cellAlign: 'center',
            contain: true,
            imagesLoaded: true,
            adaptiveHeight: true,
            groupCells: false,
            lazyLoad: true,
            wrapAround: true,
            pageDots: true,
            prevNextButtons: true,
            autoPlay: true
          });
        });
      }, 200);
    }
    if (sliderAlert) {
      sliderAlert.forEach(function ($slider) {
        var $sliderSection = $slider.querySelector('.alert-content-wrapper');
        var flkty = new Flickity($sliderSection, {
          // options
          resize: true,
          cellAlign: 'center',
          contain: true,
          imagesLoaded: true,
          adaptiveHeight: true,
          groupCells: false,
          lazyLoad: true,
          wrapAround: true,
          pageDots: false,
          prevNextButtons: false,
          autoPlay: true
        });
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();