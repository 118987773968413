"use strict";

window.site = window.site || {};

/**
 * Init Twitter related scripts
 * @class Site
 * @static
 */
site.HSForm = function HSForm() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
     * Has the class been initialized?
     * @private
     */
  var inited = false;

  /**
     * Initializes the class.
     * @public
     */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    var hsForm = document.querySelector('[data-hs-form]');
    if (hsForm) {
      var portalId = hsForm.dataset.hsPortalId;
      var formId = hsForm.dataset.hsFormId;
      hsForm.addEventListener('submit', function (e) {
        e.preventDefault();
        var emailInput = hsForm.querySelector('#hubspot-email');
        var pageNameInput = hsForm.querySelector('input[name="pageName"]');
        var pageUriInput = hsForm.querySelector('input[name="pageUri"]');
        var validationDiv = hsForm.querySelector('.validation-wrapper');
        var ipAddress = '';
        if (emailInput) {
          if (emailInput.value !== '' && /^\w+([+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailInput.value)) {
            axios({
              url: 'https://api.ipify.org?format=json',
              method: 'GET'
            }).then(function (res) {
              console.log('res :: ', {
                res: res
              });
              return res.data;
            }).then(function (data) {
              console.log('data :: ', data);
              ipAddress = data.ip;
              axios({
                url: "https://api.hsforms.com/submissions/v3/integration/submit/".concat(portalId, "/").concat(formId),
                method: 'POST',
                data: {
                  fields: [{
                    name: 'email',
                    value: emailInput.value
                  }],
                  context: {
                    ipAddress: ipAddress,
                    pageName: pageNameInput.value,
                    pageUri: pageUriInput.value
                  }
                }
              }).then(function (res) {
                console.log('res :: ', {
                  res: res
                });
                return res.data;
              }).then(function (data) {
                console.log('data :: ', data);
                validationDiv.innerHTML = data.inlineMessage;
                emailInput.value = '';
                setTimeout(function () {
                  validationDiv.innerHTML = '';
                }, 5000);
              })["catch"](function (error) {
                console.log('error :: ', error);
                validationDiv.innerHTML = error.inlineMessage;
                emailInput.value = '';
                setTimeout(function () {
                  validationDiv.innerHTML = '';
                }, 5000);
              });
            })["catch"](function (error) {
              console.log('error :: ', error);
            });
          } else {
            if (emailInput.value === '') {
              validationDiv.innerHTML = '<p>The email field is required</p>';
              setTimeout(function () {
                validationDiv.innerHTML = '';
              }, 5000);
            } else if (!/^\w+([+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailInput.value)) {
              validationDiv.innerHTML = '<p>The email is invalid</p>';
              setTimeout(function () {
                validationDiv.innerHTML = '';
              }, 5000);
            }
          }
        }
      });
    }
    inited = true;
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();