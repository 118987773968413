"use strict";

window.site = window.site || {};

/**
 * Init Twitter related scripts
 * @class Site
 * @static
 */
// eslint-disable-next-line no-undef
site.Twitter = function Twitter() {
  /**
     * jQuery elements
     * @private
     */

  /**
     * Has the class been initialized?
     * @private
     */
  var inited = false;

  /**
     * Initializes the class.
     * @public
     */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var twitterFeed = document.querySelector('.twitter-feed');
    var twitterMoreBtn = document.querySelector('.twitter-feed-btn');
    if (twitterFeed) {
      var macy = Macy({
        container: twitterFeed,
        trueOrder: false,
        waitForImages: false,
        margin: 15,
        columns: 3,
        breakAt: {
          1024: 2,
          767: 1
        }
      });
      twitterMoreBtn.addEventListener('click', function (e) {
        e.preventDefault();
        var twitterCards = twitterFeed.querySelectorAll('.twit-wrapper.not-display');
        twitterCards.forEach(function (elem, index) {
          elem.classList.remove('not-display');
        });
        macy.remove();
        macy = Macy({
          container: twitterFeed,
          trueOrder: false,
          waitForImages: false,
          margin: 15,
          columns: 3,
          breakAt: {
            1024: 2,
            767: 1
          }
        });
        twitterMoreBtn.text = 'Go on Twitter';
        if (twitterCards.length === 0) {
          window.open('https://twitter.com/WSPS_NEWS', '_blank');
        }
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();