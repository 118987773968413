"use strict";

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.Orders = function Orders() {
  /**
  * jQuery elements
  * @private
  */
  var accountOverlay = document.querySelector('.account-overlay');
  var sortbySelect = document.querySelector('select.sortby-select');
  var ordersContainer = document.querySelector('section.orders-container');
  var employeesForm = document.querySelector('.employees-form');

  /**
  * Has the class been initialized?
  * @private
  */
  var inited = false;

  /**
  * Initializes the class.
  * @public
  */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    displayLoader(7000);
    if (ordersContainer) {
      fetchCrmOrders();
    }

    // Sort by select
    if (sortbySelect) {
      sortbySelect.addEventListener('change', function (e) {
        e.preventDefault();
        displayLoader(5000);
        fetchCmsOrders(null);
      });
    }
    return true;
  };

  /**
   * Fetch orders from the CRM
   **/
  var fetchCrmOrders = function fetchCrmOrders() {
    var formData = new FormData();
    var emailInput = document.querySelector('.products-linked input[name="userEmail"]');
    var email = emailInput ? emailInput.value : '';
    var csrfName = site.App.config('csrfName');
    var csrfToken = site.App.config('csrf');
    formData.append(csrfName, csrfToken);
    formData.append('email', email);
    axios({
      url: "actions/site-module/orders/fetch-crm-orders",
      method: 'POST',
      data: formData
    }).then(function (res) {
      var data = res.data;
      ordersContainer.innerHTML = data;
      handleProductsActions();
      handlePagination();
    })["catch"](function (err) {
      console.log(err);
    });
  };

  /**
   * Fetch orders from the CMS with filter and pagination
   **/
  var fetchCmsOrders = function fetchCmsOrders(nextPage) {
    var formData = new FormData();
    var emailInput = document.querySelector('.products-linked input[name="userEmail"]');
    var email = emailInput ? emailInput.value : '';
    var csrfName = site.App.config('csrfName');
    var csrfToken = site.App.config('csrf');
    var sortbySelect = document.querySelector('select.sortby-select');
    var sortby = sortbySelect ? sortbySelect.value : '';
    var page = nextPage ? nextPage : 1;
    formData.append(csrfName, csrfToken);
    formData.append('email', email);
    formData.append('sortby', sortby);
    formData.append('page', page);
    axios({
      url: "actions/site-module/orders/fetch-cms-orders",
      method: 'POST',
      data: formData
    }).then(function (res) {
      var data = res.data;
      ordersContainer.innerHTML = data;
      handleProductsActions();
      handlePagination();
      scrollToTop();
    })["catch"](function (err) {
      console.log(err);
    });
  };

  /**
   * Handle pagination
   **/
  var handlePagination = function handlePagination() {
    var ordersPagination = document.querySelector('.pagination.for-orders ul');
    if (ordersPagination) {
      var pageItems = ordersPagination.querySelectorAll('li.page-item');
      pageItems.forEach(function (pageItem) {
        if (pageItem) {
          pageItem.addEventListener('click', function (e) {
            e.preventDefault();
            var newPage = pageItem.getAttribute('data-page');
            displayLoader(5000);
            fetchCmsOrders(newPage);
          });
        }
      });
    }
  };

  /**
   * Display loader
   **/
  var displayLoader = function displayLoader(timeout) {
    if (accountOverlay) {
      accountOverlay.classList.add('is-active');
      setTimeout(function () {
        if (accountOverlay.classList.contains('is-active')) {
          accountOverlay.classList.remove('is-active');
        }
      }, timeout);
    }
  };

  /**
   * Scroll to top smoothly
   **/
  var scrollToTop = function scrollToTop() {
    var scrollContainer = document.querySelector('.products-linked');
    var scrollContainerTop = scrollContainer.getBoundingClientRect().top - 70 + window.pageYOffset;
    window.scrollTo({
      top: scrollContainerTop,
      behavior: 'smooth'
    });
  };

  /**
   * Handle actions for products
   **/
  var handleProductsActions = function handleProductsActions() {
    var productWrappers = document.querySelectorAll('.product-wrapper');
    if (productWrappers) {
      productWrappers.forEach(function (productWrapper) {
        // Handle product employees checkboxes
        handleProductEmployeesRow(productWrapper);

        // Show/hide product details
        displayProductDetails(productWrapper);

        // Open add employees form
        openAddEmployeesForm(productWrapper);

        // Add selected employees to the product
        addSelectedEmployees(productWrapper);
      });
    }
  };

  /**
   * Display product details
   **/
  var displayProductDetails = function displayProductDetails(productWrapper) {
    var productEditEmployees = productWrapper.querySelector('.edit-product-employees');
    var productEmployees = productWrapper.querySelector('.product-employees');
    if (productEditEmployees && productEmployees) {
      productEditEmployees.addEventListener('click', function (e) {
        e.preventDefault();
        if (productEmployees.classList.contains('is-open')) {
          productEmployees.classList.remove('is-open');
        } else {
          productEmployees.classList.add('is-open');
        }
      });
    }
  };

  /**
   * Open add employees form
   **/
  var openAddEmployeesForm = function openAddEmployeesForm(productWrapper) {
    var addEmployeesButton = productWrapper.querySelector('.add-employees.product-employees-add');
    addEmployeesButton.addEventListener('click', function (e) {
      e.preventDefault();
      if (employeesForm) employeesForm.classList.add('is-open');
    });
  };

  /**
   * Handle product employees checkboxes
   **/
  var handleProductEmployeesRow = function handleProductEmployeesRow(productWrapper) {
    var employeeRows = productWrapper.querySelectorAll('.product-employee-wrapper');
    employeeRows.forEach(function (employeeRow) {
      var employeeCheckbox = employeeRow.querySelector('.input-checkbox-product');
      var employeId = employeeRow.dataset.employeId;
      var orderNumber = employeeRow.dataset.transactionId;
      var instantInputs = document.createElement('div');
      instantInputs.className = 'instant-inputs';
      if (employeeCheckbox) {
        if (employeeCheckbox.checked) {
          var input1 = "<input type=\"hidden\" name=\"fields[employees][blocks][".concat(employeId, "][fields][linkedProducts][sortOrder][]\" value=\"new1\">");
          var input2 = "<input type=\"hidden\" name=\"fields[employees][blocks][".concat(employeId, "][fields][linkedProducts][blocks][new1][type]\" value=\"informations\">");
          var input3 = "<input type=\"hidden\" name=\"fields[employees][blocks][".concat(employeId, "][fields][linkedProducts][blocks][new1][fields][uidTransaction]\" value=\"").concat(orderNumber, "\">");
          instantInputs.innerHTML = "".concat(input1, " ").concat(input2, " ").concat(input3);
          employeeRow.appendChild(instantInputs);
        } else {
          employeeCheckbox.addEventListener('change', function (e) {
            if (employeeCheckbox.checked) {
              var _input = "<input type=\"hidden\" name=\"fields[employees][blocks][".concat(employeId, "][fields][linkedProducts][sortOrder][]\" value=\"new1\">");
              var _input2 = "<input type=\"hidden\" name=\"fields[employees][blocks][".concat(employeId, "][fields][linkedProducts][blocks][new1][type]\" value=\"informations\">");
              var _input3 = "<input type=\"hidden\" name=\"fields[employees][blocks][".concat(employeId, "][fields][linkedProducts][blocks][new1][fields][uidTransaction]\" value=\"").concat(orderNumber, "\">");
              instantInputs.innerHTML = "".concat(_input, " ").concat(_input2, " ").concat(_input3);
              employeeRow.appendChild(instantInputs);
            } else {
              if (employeeRow.querySelector('.instant-inputs')) {
                employeeRow.removeChild(instantInputs);
              }
            }
          });
        }
      }
    });
  };

  /**
   * Add selected employees to product
   **/
  var addSelectedEmployees = function addSelectedEmployees(productWrapper) {
    var checkedInputs = 0;
    var checkedClick = 0;
    var employeeSubmitButton = productWrapper.querySelector('button.product-employee-submit');
    var employeeMsg = productWrapper.querySelector('span.product-employee-message');
    var employeeWaitingBox = productWrapper.querySelector('span.waiting-box');
    var productEmployeesForm = productWrapper.querySelector('form.form-add-employee-product');
    if (employeeWaitingBox && employeeSubmitButton && productEmployeesForm) {
      var dataQuantity = productEmployeesForm.dataset.quantity;
      employeeWaitingBox.style.display = 'none';
      employeeSubmitButton.addEventListener('click', function (e) {
        e.preventDefault();
        for (var i = 0; i < productEmployeesForm.length; i++) {
          if (productEmployeesForm[i].type === 'checkbox') {
            if (productEmployeesForm[i].checked) {
              checkedInputs++;
            }
          }
        }
        if (dataQuantity >= checkedInputs) {
          if (dataQuantity == checkedInputs) {
            checkedClick++;
            employeeSubmitButton.disabled = true;
            employeeSubmitButton.style.display = 'none';
            employeeMsg.style.display = 'none';
            employeeWaitingBox.style.display = 'block';
            if (accountOverlay) {
              accountOverlay.classList.add('is-active');
            }
            disableScroll();
            var formData = new FormData();
            for (var _i = 0; _i < productEmployeesForm.length; ++_i) {
              if (productEmployeesForm[_i].type === 'checkbox') {
                if (productEmployeesForm[_i].checked) {
                  formData.append(productEmployeesForm[_i].name, productEmployeesForm[_i].value);
                  checkedInputs++;
                }
              } else {
                formData.append(productEmployeesForm[_i].name, productEmployeesForm[_i].value);
              }
            }
            if (checkedClick >= 1) {
              axios({
                url: 'actions/site-module/default/post-employees',
                method: 'POST',
                data: formData
              }).then(function (res) {
                return res.data;
              }).then(function (data) {
                productEmployeesForm.submit();
              })["catch"](function (error) {
                console.log('error :: ', error);
              });
            }
          } else {
            productEmployeesForm.submit();
          }
        } else {
          window.alert('too many registrants for this course');
        }
        checkedInputs = 0;
        dataQuantity = dataQuantity;
      });
    }
  };

  // call this to Disable
  var disableScroll = function disableScroll() {
    // left: 37, up: 38, right: 39, down: 40,
    // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
    var keys = {
      37: 1,
      38: 1,
      39: 1,
      40: 1
    };
    function preventDefault(e) {
      e.preventDefault();
    }
    function preventDefaultForScrollKeys(e) {
      if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
      }
    }

    // modern Chrome requires { passive: false } when adding event
    var supportsPassive = false;
    try {
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function get() {
          supportsPassive = true;
        }
      }));
    } catch (e) {}
    var wheelOpt = supportsPassive ? {
      passive: false
    } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();