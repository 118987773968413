"use strict";

window.site = window.site || {};

/**
 * Init Twitter related scripts
 * @class Site
 * @static
 */
site.CoveoCustom = function CoveoCustom() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */
  var $coveoPopup = document.querySelector('.js-coveo-search-popup'),
    $coveoPopupTrigger = document.querySelector('.js-coveo-popup-trigger'),
    $closeCoveoPopup = document.querySelector('.js-close-search-bar'),
    $searchIcon = document.querySelector('.js-search-icon'),
    $closeIcon = document.querySelector('.js-close-icon');

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    $coveoPopupTrigger.addEventListener('click', function (e) {
      e.preventDefault();
      $coveoPopup.classList.toggle('is-active');
      $searchIcon.classList.toggle('is-hidden');
      $closeIcon.classList.toggle('is-hidden');
    });
    $closeCoveoPopup.addEventListener('click', function (e) {
      $coveoPopup.classList.remove('is-active');
      $searchIcon.classList.remove('is-hidden');
      $closeIcon.classList.add('is-hidden');
    });
    inited = true;
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();