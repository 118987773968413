"use strict";

window.site = window.site || {};

/**
 * Init AjaxPagination related scripts
 * @class Site
 * @static
 */
site.CopyLinks = function CopyLinks() {
  /**
     * jQuery elements
     * @private
     */
  var $copyLinks = document.querySelectorAll('[data-copy-link]');

  /**
     * Has the class been initialized?
     * @private
     */
  var inited = false;

  /**
     * Initializes the class.
     * @public
     */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    if ($copyLinks.length) {
      $copyLinks.forEach(function ($link) {
        $link.addEventListener('click', function (e) {
          e.preventDefault();
          var $hiddenInput = $link.previousElementSibling;
          var $successMsg = $link.nextElementSibling;
          if ($hiddenInput) {
            $hiddenInput.focus({
              preventScroll: true
            });
            $hiddenInput.select();
            try {
              var $successful = document.execCommand('copy');
              if ($successful && $successMsg) {
                $successMsg.classList.toggle('is-active');
                setTimeout(function () {
                  $successMsg.classList.toggle('is-active');
                }, 5000);
              }
            } catch (err) {
              console.log('Oops, unable to copy');
            }
          }
        });
      });
    }
    inited = true;
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();